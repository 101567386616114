import React from "react";
import "./index.scss";

const Commitments = () => {
  return (
    <div className="PgDP-I-section PgDP-I-Commitments">
      <h2 className="PgDP-I-section__title">Commitments</h2>
      <p>We have outlined the following commitments to our company:</p>
      <ol>
        <li>
          To create an environment in which individual differences and the
          contributions of all team members are recognized and valued.
        </li>
        <li>
          To create a work environment that promotes dignity and respect for
          every employee.
        </li>
        <li>
          To not tolerate any form of intimidation, bullying, or harassment, and
          to discipline those who breach this policy.
        </li>
        <li>
          To make training, development, and progression opportunities available
          to all staff.
        </li>
        <li>
          To promote equity in the workplace, which is a good management
          practice and makes sound business sense.
        </li>
        <li>
          To encourage anyone who feels they have been subject to discrimination
          to voice their concerns so we can implement corrective measures.
        </li>
        <li>
          To encourage employees to treat everyone with dignity and respect.
        </li>
        <li>
          To regularly review all our employment practices and procedures so
          that fairness is maintained at all times.
        </li>
        <li>
          To comply with legal requirements with respect to diversity, equality,
          and prohibition of discrimination including The Pennsylvania Human
          Relations Act (PHRA) and Pennsylvania's Ethnic Intimidation Statute
          (18 Pa. C.S.A. § 2710), and take the necessary measures to help
          enforce relevant laws if someone believes they have been discriminated
          against or intimidated.
        </li>
        <li>
          To review employment practices and procedures when necessary to ensure
          fairness, and also update our procedures and policies in keeping with
          changes in the law.
        </li>
      </ol>
      <p>
        All employees of AMZScout have a responsibility to treat others with
        dignity and respect at all times. All employees are expected to exhibit
        conduct that reflects inclusion during the work day, at work functions
        on the work site, and at all other company-sponsored and participative
        events.
      </p>
    </div>
  );
};

export default Commitments;
