import React from "react";
import "./index.scss";

const Monitoring = () => {
  return (
    <div className="PgDP-I-section">
      <h2 className="PgDP-I-section__title">Monitoring</h2>
      <p>
        The AMZScout company shall perform monitoring, which includes assessing
        how the equality, diversity, and inclusion policy, and any supporting
        actions/plans are working in practice, reviewing them annually, and
        considering and taking action to address any issues.
      </p>
    </div>
  );
};

export default Monitoring;
