import React from "react";
import "./index.scss";

const GrievanceProcedures = () => {
  return (
    <div className="PgDP-I-section">
      <h2 className="PgDP-I-section__title">Grievance procedures</h2>
      <p>
        Every employee is entitled to raise a grievance to their line manager,
        or directly to the CEO of AMZScout. All appeals concerning this equity,
        diversity, and inclusion policy shall be carefully reviewed by the
        authorized officer within 2 (two) weeks.
      </p>
      <p>
        Use of the organization's grievance or disciplinary procedures does not
        affect an employee's right to make a claim to the competent
        court/tribunal and/or use other legal remedies and measures.
      </p>
    </div>
  );
};

export default GrievanceProcedures;
