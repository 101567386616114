import React from "react";
import "./index.scss";

const Background = () => {
  return (
    <div className="PgDP-I-section">
      <h2 className="PgDP-I-section__title">Background</h2>
      <p>
        AMZScout Corp. (hereinafter referred to as AMZScout) incorporated in
        Pennsylvania, USA is an international IT company that provides digital
        services and tools for merchants involved in e-commerce on online
        marketplaces.
      </p>
      <p>
        AMZScout is committed to creating a safe work environment where all
        employees are included and treated with dignity and respect.
      </p>
      <p>
        AMZScout recognizes equitable inclusion of people of all gender
        identities, ages, races, ethnicities, national origins, cultures,
        religious or political beliefs, languages, education levels,
        socio-economic backgrounds, family or relationship statuses, sexual
        orientations, genetics, and/or abilities as a priority with regard to
        all members of our team, as well our clients and contractors.
      </p>
      <p>
        Our aim is to ensure that all employees and job applicants are given
        equal opportunity. Each employee will be respected, and valued, and as a
        result will be able to give their best. All of AMZScout's employees, job
        applicants, partners, contractors, and clients shall be treated
        respectfully and equally.
      </p>
      <p>
        This policy declares our commitment to ensuring a sense of equality and
        fairness for all employees, who will be provided with equal access to
        quality facilities and fair treatment, regardless of each individual's
        age, caring responsibilities, disability, family circumstances, gender
        expression, gender identity, gender reassignment, marriage, and civil
        partnership, pregnancy and maternity, race including ethnic origin, skin
        color, nationality and national origin, religion or beliefs, trade union
        activity, sex, sexual orientation, socio-economic background, or the
        effects of the menopause.
      </p>
      <p>
        All employees, whether they are part-time, full-time, or temporary
        employees, will be treated fairly and with respect. When selecting
        candidates for employment, promotion, training, or any other benefit,
        these determinations will be on the basis of each person's abilities and
        professional skills.
      </p>
    </div>
  );
};

export default Background;
