import React from "react";
import Background from "./Background";
import Definitions from "./Definitions";
import Commitments from "./Commitments";
import Monitoring from "./Monitoring";
import GrievanceProcedures from "./GrievanceProcedures";
import "./index.scss";

const Info = () => {
  return (
    <section className="PgDP-I">
      <div className="PgDP__container">
        <h1 className="PgDP-I__title">
          DIVERSITY, EQUITY, AND INCLUSION POLICY
        </h1>
        <Background />
        <Definitions />
        <Commitments />
        <Monitoring />
        <GrievanceProcedures />
      </div>
    </section>
  );
};

export default Info;
