import React from "react";
import "./index.scss";

const Definitions = () => {
  return (
    <div className="PgDP-I-section">
      <h2 className="PgDP-I-section__title">Definitions</h2>
      <p>
        <b>Diversity</b> refers to the company's diverse society, in terms of
        gender, age, language, national origin, religion or belief, culture, and
        other differences and similarities. We value diversity because it
        strengthens the company and encourages creativity. A diverse work
        environment enhances inclusivity and fosters a sense of belonging within
        our team.
      </p>
      <p>
        <b>Inclusion</b> means that all individuals are treated fairly and
        respectfully, have equal access to opportunities and resources, and can
        contribute fully to the organization's collective objectives.
        Maintaining a diverse environment and promoting inclusion are two
        mutually beneficial and complementary approaches we honor and respect.
      </p>
      <p>
        <b>Equity</b> refers to the provision of consistent, systematic, fair,
        and impartial treatment for all employees, contractors, and clients.
      </p>
      <p>
        <b>Belonging</b> reflects each employee's sense that they are welcome,
        accepted, valued, and appreciated for their diverse backgrounds and
        experiences. An inclusive work environment helps foster a sense of
        belonging.
      </p>
    </div>
  );
};

export default Definitions;
